import React, { Fragment, useState, useEffect } from "react";

//redux
import { useSelector, useDispatch, connect } from "react-redux";
import { CLOSE_BELL } from "../../store/notification/types";
import { insertNotification } from "../../store/notification/action";
//icon
import Cancel from "@material-ui/icons/Cancel";
import {
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { baseURL } from "../../util/Config";

//dialog
import Dialog from "@material-ui/core/Dialog";


const NotificationDialog = (props) => {
  const { dialog: open, dialogData } = useSelector(
    (state) => state.notification
  );

  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [image, setImage] = useState([]);
  const [imagePath, setImagePath] = useState("");

  const [error, setError] = useState({
    title: "",
    message: "",
    image: "",
  });

  useEffect(() => {
    if (dialogData) {
      setTitle(dialogData.title);
      setImagePath(baseURL + dialogData.image);
      setMessage(dialogData?.message);
    }
  }, [dialogData]);

  useEffect(() => {
    setError({
      title: "",
      message: "",
      image: "",
    });
    setTitle("");
    setMessage("");
    setImagePath("");
  }, [open]);

  const imageUpload = (e) => {
    // if (e.target.files[0] == "") {
    //   setError({
    //     ...error,
    //     image: "image is required",
    //   });
    // } else {
    //   setError({
    //     ...error,
    //     image: "",
    //   });
    // }
    setImage(e.target.files[0]);
    setImagePath(URL.createObjectURL(e.target.files[0]));
  };

  const send = () => {
    if (!message || image.length === "" || !title) {
      let error = {};
      if (!message) {
        error.message = "message Is Required..!";
      }
      if (!title) {
        error.title = "title Is Required..!";
      }

      // if (image.length === 0 && image == "") {
      //   error.image = "image is required..!";
      // }
      // console.log("::::::::::>", image);

      return setError({ ...error });
    } else {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("image", image);
      formData.append("message", message);

      props.insertNotification(formData);
    }
    dispatch({ type: CLOSE_BELL });
  };

  const closePopup = () => {
    dispatch({ type: CLOSE_BELL });
  };
  return (
    <Fragment>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4"> Message </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="form-group">
                  <label className="mb-2 text-gray">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Add Title"
                    defaultValue
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      if (!e.target.value) {
                        return setError({ ...error, title: "URL is required" });
                      } else {
                        return setError({ ...error, title: "" });
                      }
                    }}
                  />
                  {error.title && <p style={{ color: "red" }}>{error.title}</p>}
                </div>
                <div className="form-group mt-2">
                  <label className="mb-2 text-gray">Message</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Message"
                    defaultValue
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...error,
                          message: "URL is required",
                        });
                      } else {
                        return setError({ ...error, message: "" });
                      }
                    }}
                  />
                  {error.message && (
                    <p style={{ color: "red" }}>{error.message}</p>
                  )}
                </div>
                <div className="form-group mt-2">
                  <label className="mb-2 text-gray">Image</label>
                  <input
                    type="file"
                    className="form-control form-control-sm"
                    accept="image/*"
                    onChange={(e) => imageUpload(e)}
                  />
                  {imagePath ? (
                    <img
                      src={imagePath}
                      style={{
                        height: "100px",
                        width: "100px",
                        margin: "10px",
                      }}
                    ></img>
                  ) : (
                    ""
                  )}
                  {/* {error.image && <p style={{ color: "red" }}>{error.image}</p>}*/}
                </div>
                <div className="mt-5">
                  <button
                    type="button"
                    className="btn btn-round float__right btn-success"
                    onClick={send}
                  >
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default connect(null, { insertNotification })(NotificationDialog);
