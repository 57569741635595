import axios from "axios";
import { key } from "../../util/Config";
import { CLOSE_BELL, OPEN_BELL, INSERT_BANNER } from "./types";
import { Toast } from "../../util/Toast";

export const insertNotification = (formData) => (dispatch) => {
  axios
    .post(`/sendByAdmin?key=${key}`, formData)
    .then((response) => {
      if (response.data.status) {
        dispatch({ type: INSERT_BANNER, payload: response.data.message });
        Toast("success", "Message send successfully!");
        dispatch({ type: CLOSE_BELL });
      } else {
        Toast("error", response.data.message);
      }
      // console.log("sendByAdmin::::::::::::::>", response.data.message);
    })
    .catch((error) => {
      console.log(error);
    });
};
