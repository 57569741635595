export const GET_FAKE_USER = "GET_FAKE_USER";
export const INSERT_FAKE_USER = "INSERT_FAKE_USER";
export const EDIT_FAKE_USER = "EDIT_FAKE_USER";
export const BLOCK_UNBLOCK_SWITCH = "BLOCK_UNBLOCK_SWITCH";

export const GET_HISTORY = "GET_HISTORY";
export const GET_COUNTRY = "GET_COUNTRY";

export const EDIT_COIN = "EDIT_COIN";


export const HANDLE_LOCK_SWITCH = "HANDLE_LOCK_SWITCH";
