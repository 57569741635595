import {
  GET_ANSWER,
  CREATE_NEW_ANSWER,
  OPEN_ANSWER_DIALOG,
  CLOSE_ANSWER_DIALOG,
  EDIT_ANSWER,
  DELETE_ANSWER,
} from "./type";

const initialState = {
  answer: [],
  dialog: false,
  dialogData: null,
};

const answerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ANSWER:
      return {
        ...state,
        answer: action.payload,
      };
    case CREATE_NEW_ANSWER:
      const data = [...state.answer, ...action.payload];
      return {
        ...state,
        answer: data,
      };
    case EDIT_ANSWER:
      return {
        ...state,
        answer: state.answer.map((answer) => {
          if (answer._id === action.payload.id) return action.payload.data;
          else return answer;
        }),
      };
    case DELETE_ANSWER:
      return {
        ...state,
        answer: state.answer.filter((answer) => answer._id !== action.payload),
      };
    case OPEN_ANSWER_DIALOG:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };
    case CLOSE_ANSWER_DIALOG:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    default:
      return state;
  }
};

export default answerReducer;
