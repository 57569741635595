/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from "react";

// routing
import { Link, useHistory } from "react-router-dom";

// redux
import { connect, useSelector } from "react-redux";

//action
import { getCountry, insertUser, editUser } from "../../store/fakeUser/action";

import { permissionError } from "../../util/Alert";

const FakeUserPage = (props) => {
  const history = useHistory();
  const hasPermission = useSelector((state) => state.admin.admin.flag);

  const detail = JSON.parse(localStorage.getItem("fakeUser"));

  const [name, setName] = useState("");
  const [userName, setUserName] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState(0);
  const [image, setImage] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [imageType, setImageType] = useState("image");
  const [country, setCountry] = useState("");
  const [bio, setBio] = useState("");
  const [mongoId, setMongoId] = useState("");

  useEffect(() => {
    props.getCountry();
  }, []);

  const countries = useSelector((state) => state.fakeUser.country);

  const [errors, setError] = useState({
    name: "",
    userName: "",
    gender: "",
    age: "",
    image: "",
    country: "",
    bio: "",
  });

  useEffect(() => {
    if (detail) {
      setName(detail.name);
      setUserName(detail.username);
      setGender(detail.gender);
      setImage(detail.image);
      setImageType(detail.image.includes("/storage") ? "image" : "link");
      setAge(detail.age);
      setCountry(detail.country);
      setBio(detail.bio);
      detail.image.includes("/storage") && setImagePath(detail.image);
      setMongoId(detail._id);
    }
  }, []);

  const HandleInputImage = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!mongoId || age < 0) {
      if (!image) {
        return setError({ ...errors, image: "User Image is Required!" });
      }
      if(age < 0) {
        return setError({ ...errors, age: "User age Invalid!" });

      }
    } else {
      if (!image) {
        return setError({ ...errors, image: "User Image is Required!" });
      }
    }

    if (!name || !userName || !gender || !age || !bio || !country) {
      const errors = {};
      if (!name) errors.name = "Name is Required!";
      if (!userName) errors.userName = "UserName is Required!";
      if (!gender) errors.gender = "Gender is Required!";
      if (!age) errors.age = "Age is Required!";
      if (!bio) errors.bio = "Bio is Required!";
      if (!country) errors.country = "Country is Required!";

      return setError({ ...errors });
    }

    if (!hasPermission) return permissionError();
    const formData = new FormData();

    formData.append("image", image);
    formData.append("name", name);
    formData.append("userName", userName);
    formData.append("gender", gender);
    formData.append("country", country);
    formData.append("bio", bio);
    formData.append("age", age);
    if (mongoId) {
      props.editUser(mongoId, formData);
    } else {
      props.insertUser(formData);
    }

    setTimeout(() => {
      history.push("/admin/fake");
    }, 3000);
  };

  return (
    <>
      <div className="page-title">
        <div className="row">
          <div className="col-12 col-md-6 order-md-1 order-last">
            <h3 className="mb-3">Fake User Dialog</h3>
          </div>
          <div className="col-12 col-md-6 order-md-2 order-first">
            <nav
              aria-label="breadcrumb"
              className="breadcrumb-header float-start float-lg-end"
            >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/admin/dashboard" className="text-danger">
                    Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin/fake" className="text-danger">
                    Fake User
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Dialog
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body card-overflow">
              <div class="d-sm-flex align-items-center justify-content-between mb-4"></div>

              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="mb-2 text-gray">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        required=""
                        placeholder="Name"
                        value={name}
                        onChange={(e) => {
                          setName(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              name: "name is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              name: "",
                            });
                          }
                        }}
                      />
                      {errors.name && (
                        <div className="ml-2 mt-1">
                          {errors.name && (
                            <div className="pl-1 text__left">
                              <span className="text-red">{errors.name}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="text-gray mb-2">Country</label>

                      <>
                        <select
                          class="form-select form-control"
                          aria-label="Default select example"
                          value={country}
                          onChange={(e) => {
                            setCountry(e.target.value);
                            if (e.target.value === "Select country") {
                              return setError({
                                ...errors,
                                country: "Please select a country!",
                              });
                            } else {
                              return setError({
                                ...errors,
                                country: "",
                              });
                            }
                          }}
                        >
                          <option value="Select country" selected>
                            Select country
                          </option>
                          {countries.map((country) => {
                            return (
                              <option value={country.countryName}>
                                {country.countryName}
                              </option>
                            );
                          })}
                        </select>
                        {errors.country && (
                          <div className="ml-2 mt-1">
                            {errors.country && (
                              <div className="pl-1 text__left">
                                <span className="text-red">
                                  {errors.country}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="mb-2 text-gray">Username</label>
                      <input
                        type="text"
                        className="form-control"
                        required=""
                        placeholder="Username"
                        value={userName}
                        onChange={(e) => {
                          setUserName(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              userName: "Username is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              userName: "",
                            });
                          }
                        }}
                      />
                      {errors.userName && (
                        <div className="ml-2 mt-1">
                          {errors.name && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.userName}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="mb-2 text-gray">Age</label>
                      <input
                        type="number"
                        min="0"
                        className="form-control"
                        required=""
                        placeholder="18"
                        value={age}
                        onChange={(e) => {
                          setAge(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              age: "Age is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              age: "",
                            });
                          }
                        }}
                      />
                      {errors.age && (
                        <div className="ml-2 mt-1">
                          {errors.age && (
                            <div className="pl-1 text__left">
                              <span className="text-red">{errors.age}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-md-6 d-flex justify-content-start">
                    <label className="mb-2 text-gray">Image Type : </label>
                    <div class="form-check">
                      <input
                        class="form-check-input mx-2"
                        type="radio"
                        name="imageType"
                        id="image"
                        value="image"
                        onClick={(e) => {
                          setImageType(e.target.value);
                        }}
                        checked={imageType === "image" ? true : false}
                      />
                      <label class="form-check-label" for="image">
                        Image
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input mx-2"
                        type="radio"
                        name="imageType"
                        id="link"
                        value="link"
                        checked={imageType === "link" ? true : false}
                        onClick={(e) => {
                          setImageType(e.target.value);
                        }}
                      />
                      <label class="form-check-label" for="link">
                        Link
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex justify-content-start">
                    <label className="mb-2 text-gray">Gender : </label>
                    <div class="form-check">
                      <input
                        class="form-check-input mx-2"
                        type="radio"
                        name="gender"
                        id="male"
                        value="male"
                        checked={gender === "male" ? true : false}
                        onClick={(e) => {
                          setGender(e.target.value);
                        }}
                      />
                      <label class="form-check-label" for="male">
                        Male
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input mx-2"
                        type="radio"
                        name="gender"
                        id="female"
                        value="female"
                        checked={gender === "female" ? true : false}
                        onClick={(e) => {
                          setGender(e.target.value);
                        }}
                      />
                      <label class="form-check-label" for="female">
                        Female
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row mt-4">
                  <div
                    className={imageType === "image" ? "col-md-6" : "d-none"}
                  >
                    <div className="form-group ">
                      <label className="mb-2 text-gray">Image</label>
                      <input
                        type="file"
                        className="form-control form-control-sm"
                        accept="image/*"
                        required=""
                        onChange={HandleInputImage}
                      />
                      {errors.image && (
                        <div className="ml-2 mt-1">
                          {errors.image && (
                            <div className="pl-1 text__left">
                              <span className="text-red">{errors.image}</span>
                            </div>
                          )}
                        </div>
                      )}
                      {imagePath && (
                        <>
                          <img
                            height="60px"
                            width="60px"
                            alt="app"
                            src={imagePath}
                            style={{
                              boxShadow: "0 5px 15px 0 rgb(105 103 103 / 00%)",
                              border: "2px solid #fff",
                              borderRadius: 10,
                              marginTop: 10,
                            
                              objectFit: "contain",
                              marginRight: 15,
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <div className={imageType === "link" ? "col-md-6" : "d-none"}>
                    <div className="form-group">
                      <label className="mb-2 text-gray">Link</label>
                      <input
                        type="text"
                        className="form-control"
                        required=""
                        placeholder="Image link"
                        value={image}
                        onChange={(e) => {
                          setImage(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              image: "Image is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              image: "",
                            });
                          }
                        }}
                      />
                      {errors.bio && (
                        <div className="ml-2 mt-1">
                          {errors.bio && (
                            <div className="pl-1 text__left">
                              <span className="text-red">{errors.image}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group ">
                      <label className="mb-2 text-gray">Bio</label>
                      <input
                        type="text"
                        className="form-control"
                        required=""
                        placeholder="Bio"
                        value={bio}
                        onChange={(e) => {
                          setBio(e.target.value);
                          if (!e.target.value) {
                            return setError({
                              ...errors,
                              bio: "Bio is Required!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              bio: "",
                            });
                          }
                        }}
                      />
                      {errors.bio && (
                        <div className="ml-2 mt-1">
                          {errors.bio && (
                            <div className="pl-1 text__left">
                              <span className="text-red">{errors.bio}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className={imagePath ? "mt-5 pt-5" : "mt-5"}>
                    <button
                      type="button"
                      className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                      onClick={() => {
                        history.push("/admin/fake");
                      }}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-round float__right btn-danger"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(null, { getCountry, insertUser, editUser })(
  FakeUserPage
);
