export const GET_POST = "GET_POST";
export const GET_COMMENT = "GET_COMMENT";
export const GET_LIKE = "GET_LIKE";
export const DELETE_POST = "DELETE_POST";
export const DELETE_COMMENT = "DELETE_COMMENT";

export const INSERT_POST = "INSERT_POST";
export const EDIT_POST = "EDIT_POST";

export const HANDLE_LOCK_SWITCH = "HANDLE_LOCK_SWITCH";
