import axios from "axios";
import { Toast } from "../../util/Toast";
import {
  GET_ANSWER,
  CREATE_NEW_ANSWER,
  EDIT_ANSWER,
  CLOSE_ANSWER_DIALOG,
  DELETE_ANSWER,
} from "./type";

export const getAnswer = () => (dispatch) => {
  axios
    .get(`answer`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_ANSWER, payload: res.data.answer });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const createNewAnswer = (data) => (dispatch) => {
  axios
    .post(`answer`, data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "ANSWER created successfully!");
        dispatch({ type: CLOSE_ANSWER_DIALOG });
        dispatch({ type: CREATE_NEW_ANSWER, payload: res.data.answer });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const editAnswer = (answerId, data) => (dispatch) => {
  axios
    .patch(`answer/${answerId}`, data)
    .then((res) => {
      if (res.data.status) {
        Toast("success", "ANSWER updated successfully!");
        dispatch({ type: CLOSE_ANSWER_DIALOG });
        dispatch({
          type: EDIT_ANSWER,
          payload: { data: res.data.answer, id: answerId },
        });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
export const deleteAnswer = (answerId) => (dispatch) => {
  axios
    .delete(`answer/${answerId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: DELETE_ANSWER, payload: answerId });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
