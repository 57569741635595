import axios from "axios";
import {
  CLOSE_DIALOG,
  DELETE_MESSAGE,
  GET_MESSAGE,
  INERT_MESSAGE,
  SET_CREATE_MESSAGE_DONE,
  SET_UPDATE_MESSAGE_DONE,
  UPDATE_MESSAGE,
} from "./type";

import { Toast } from "../../util/Toast";

//Get Message
export const getMessage = () => (dispatch) => {
  axios
    .get(`message`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_MESSAGE, payload: res.data.message });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

//Insert Message
export const insertMessage = (content) => (dispatch) => {
  axios
    .post(`message/create`, content)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: INERT_MESSAGE, payload: res.data.message });
        Toast("success", "Message add successfully!");
        dispatch({ type: CLOSE_DIALOG });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => {
      console.log(error);
    });
};

//Update Message
export const updateMessage = (content, id) => (dispatch) => {
  axios
    .patch(`message/messageId?messageId=${id}`, content)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: UPDATE_MESSAGE,
          payload: { data: res.data.message, id: id },
        });
        Toast("success", "Message update successfully!");
        dispatch({ type: CLOSE_DIALOG });
      } else {
        Toast("error", res.data.message);
      }
    })

    .catch((error) => {
      console.log(error);
    });
};

//Delete Message
export const deleteMessage = (id) => (dispatch) => {
  axios
    .delete(`message/messageId?messageId=${id}`)
    .then((res) => {
      dispatch({ type: DELETE_MESSAGE, payload: id });
    })
    .catch((error) => {
      console.log(error);
    });
};
