/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from "react";

import SVGA from "svgaplayerweb";

//redux
import { connect, useDispatch, useSelector } from "react-redux";

//MUI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";

//types
import { CLOSE_GIFT_DIALOG } from "../../../store/gift/types";

//action
import { editGift } from "../../../store/gift/action";
import { getCategory } from "../../../store/giftCategory/action";

// server path
import { baseURL } from "../../../util/Config";

import { permissionError } from "../../../util/Alert";

const GiftDialog = (props) => {
  const dispatch = useDispatch();

  const { dialog: open, dialogData } = useSelector((state) => state.gift);

  const GiftClick = localStorage.getItem("GiftClick");

  const [mongoId, setMongoId] = useState("");
  const [coin, setCoin] = useState("");
  const [category, setCategory] = useState("");
  const [imageData, setImageData] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [svgaImage, setSvgaImage] = useState(null);
  const [giftType, setGiftType] = useState("");

  const categoryDetail = JSON.parse(localStorage.getItem("Category"));

  const hasPermission = useSelector((state) => state.admin.admin.flag);

  useEffect(() => {
    props.getCategory(); // eslint-disable-next-line
  }, []);

  const categories = useSelector((state) => state.giftCategory.giftCategory);

  const [errors, setError] = useState({
    image: "",
    coin: "",
    category: "",
    giftType: "",
  });

  useEffect(() => {
    if (dialogData) {
      setMongoId(dialogData._id);
      setCoin(dialogData.coin);
      setCategory(
        GiftClick === null ? dialogData.category._id : dialogData.category
      );
      setImagePath(baseURL + dialogData.image);
      if (dialogData.type === 2) {
        setSvgaImage(dialogData);
        setGiftType("svga");
      }
      else {
        setGiftType("image/gif");
      }
    }
  }, [dialogData, GiftClick]);

  useEffect(
    () => () => {
      setError({
        image: "",
        coin: "",
        category: "",
      });
      setCategory("");
      setCoin("");
      setImageData(null);
      setImagePath(null);
      setSvgaImage(null);
      setGiftType(null);
    },
    [open]
  );

  // for .svga file
  useEffect(() => {
    if (svgaImage) {
      setTimeout(() => {
        var player = new SVGA.Player(`div[attr="${svgaImage.type}"]`);
        var parser = new SVGA.Parser(`div[attr="${svgaImage.type}"]`);
        if (svgaImage.preview) {
          parser.load(svgaImage.preview, function (videoItem) {
            player.setVideoItem(videoItem);
            player.startAnimation();
          })
        }
        else {
          parser.load(baseURL + svgaImage.image, function (videoItem) {
            player.setVideoItem(videoItem);
            player.startAnimation();
          })
        }
      }, 100)
    }
  }, [svgaImage])

  const HandleInputImage = (e) => {
    if (e.target.files[0]) {
      Object.assign(e.target.files[0], { preview: URL.createObjectURL(e.target.files[0]) })
      if (e.target.files[0].type === "") {
        setSvgaImage(e.target.files[0]);
      }
      else {
        setSvgaImage(null);
      }
      setImageData(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImagePath(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const closePopup = () => {
    dispatch({ type: CLOSE_GIFT_DIALOG });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      (!imageData && !imagePath) ||
      !coin ||
      (GiftClick !== null && (!category || category === "Select Category"))
    ) {
      const errors = {};

      if (!coin) errors.coin = "Coin is Required!";

      if (!imageData && !imagePath) errors.image = "Please select an Image!";

      if (GiftClick !== null && (category === "Select Category" || !category)) {
        errors.category = "Please select a Category!";
      }

      if (!giftType || giftType === "Select Gift Type") {
        errors.giftType = "Please select a gift type!";
      }
    }
    const coinValid = isNumeric(coin);
    if (!coinValid) {
      return setError({ ...errors, coin: "Invalid Coin!!" });
    }
    const formData = new FormData();

    formData.append("image", imageData);
    formData.append("coin", coin);
    GiftClick !== null && formData.append("category", category);

    if (!hasPermission) return permissionError();

    props.editGift(formData, mongoId);
  };

  const isNumeric = (value) => {
    const val = value === "" ? 0 : value;
    const validNumber = /^\d+$/.test(val);
    return validNumber;
  };

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="responsive-dialog-title"
        onClose={closePopup}
        disableBackdropClick
        disableEscapeKeyDown
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle id="responsive-dialog-title">
          <span className="text-danger font-weight-bold h4"> Gift </span>
        </DialogTitle>

        <IconButton
          style={{
            position: "absolute",
            right: 0,
          }}
        >
          <Tooltip title="Close">
            <Cancel className="text-danger" onClick={closePopup} />
          </Tooltip>
        </IconButton>
        <DialogContent>
          <div className="modal-body pt-1 px-1 pb-3">
            <div className="d-flex flex-column">
              <form>
                <div className="form-group">
                  <label className="mb-1 text-gray">Coin</label>
                  <input
                    type="number"
                    min="0"
                    className="form-control"
                    required=""
                    placeholder="20"
                    value={coin}
                    onChange={(e) => {
                      setCoin(e.target.value);
                      if (!e.target.value) {
                        return setError({
                          ...errors,
                          coin: "Coin is Required!",
                        });
                      } else {
                        return setError({
                          ...errors,
                          coin: "",
                        });
                      }
                    }}
                  />
                  {errors.coin && (
                    <div className="ml-2 mt-1">
                      {errors.coin && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.coin}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="form-group mt-4">
                  <label className="text-gray mb-1">Category</label>
                  {GiftClick === null ? (
                    <input
                      type="text"
                      className="form-control"
                      required=""
                      placeholder="Category Name"
                      disabled
                      value={categoryDetail.name}
                    />
                  ) : (
                    <>
                      <select
                        class="form-select form-control"
                        aria-label="Default select example"
                        value={category}
                        onChange={(e) => {
                          setCategory(e.target.value);
                          if (e.target.value === "Select Category") {
                            return setError({
                              ...errors,
                              category: "Please select a Category!",
                            });
                          } else {
                            return setError({
                              ...errors,
                              category: "",
                            });
                          }
                        }}
                      >
                        <option value="Select Category" selected>
                          Select Category
                        </option>
                        {categories.map((category) => {
                          return (
                            <option value={category._id}>
                              {category.name}
                            </option>
                          );
                        })}
                      </select>
                      {errors.category && (
                        <div className="ml-2 mt-1">
                          {errors.category && (
                            <div className="pl-1 text__left">
                              <span className="text-red">
                                {errors.category}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="form-group mt-4">
                  <label className="text-gray mb-1">Type</label>
                  {/* {GiftClick === null ? (
                    <input
                      type="text"
                      className="form-control"
                      required=""
                      placeholder="Image Type"
                      disabled
                      value={svgaImage ? "svga" : "image/gif"}
                    />
                  ) : ( */}
                  <>
                    <select
                      class="form-select form-control"
                      aria-label="Default select example"
                      value={giftType}
                      onChange={(e) => {
                        setGiftType(e.target.value);
                        if (e.target.value === "Select Gift Type") {
                          return setError({
                            ...errors,
                            giftType: "Please select a Gift Type!",
                          });
                        } else {
                          return setError({
                            ...errors,
                            giftType: "",
                          });
                        }
                      }}
                    >
                      <option value="Select Gift Type" selected>
                        Select Gift Type
                      </option>
                      <option value={"image/gif"}>
                        image/gif
                      </option>
                      <option value={"svga"}>
                        svga
                      </option>
                    </select>
                    {errors.giftType && (
                      <div className="ml-2 mt-1">
                        {errors.giftType && (
                          <div className="pl-1 text__left">
                            <span className="text-red">
                              {errors.giftType}
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                  {/* )} */}
                </div>

                <div className="form-group mt-4">
                  <label className="mb-1 text-gray">Select Image or GIF or SVGA</label>
                  <input
                    type="file"
                    className="form-control form-control-sm"
                    accept={giftType == "svga" ? ".svga" : "image/*"}
                    required=""
                    onChange={HandleInputImage}
                  />
                  {errors.image && (
                    <div className="ml-2 mt-1">
                      {errors.image && (
                        <div className="pl-1 text__left">
                          <span className="text-red">{errors.image}</span>
                        </div>
                      )}
                    </div>
                  )}
                  {imagePath && (
                    svgaImage ?
                      <>
                        <div id="canvas"
                          attr={svgaImage.type}
                          style={{
                            height: "70px",
                            width: "70px",
                            boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                            // border: "2px solid #fff",
                            borderRadius: 10,
                            marginTop: 10,
                            float: "left",
                            objectFit: "cover",
                          }}
                        >
                        </div>
                      </>
                      :
                      <>
                        <img
                          height="70px"
                          width="70px"
                          alt="app"
                          src={imagePath}
                          style={{
                            boxShadow: "0 5px 15px 0 rgb(105 103 103 / 0%)",
                            // border: "2px solid #fff",
                            borderRadius: 10,
                            marginTop: 10,
                            float: "left",
                            objectFit: "cover",
                          }}
                        />
                      </>
                  )}
                </div>
                <div className={imagePath ? "mt-5 pt-5" : "mt-5"}>
                  <button
                    type="button"
                    className="btn btn-outline-info ml-2 btn-round float__right icon_margin"
                    onClick={closePopup}
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-round float__right btn-danger"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default connect(null, { editGift, getCategory })(GiftDialog);
