import { INSERT_BANNER, CLOSE_BELL, OPEN_BELL } from "./types";
const initialState = {
  notification: [],
  dialog: false,
  dialogData: null,
};

const notificationReducer = (state = initialState, action) => {
  console.log("action.type", action.type);
  switch (action.type) {
    case OPEN_BELL:
      return {
        ...state,
        dialog: true,
        dialogData: action.payload || null,
      };

    //Close Dialog
    case CLOSE_BELL:
      return {
        ...state,
        dialog: false,
        dialogData: null,
      };

    case INSERT_BANNER:
      const data = [...state.notification];
      data.unshift(action.payload);

      return {
        ...state,
        notification: data,
      };
    default:
      return state;
  }
};

export default notificationReducer;
