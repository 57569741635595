import axios from "axios";
import { baseURL, key } from "../../util/Config";
import { Toast } from "../../util/Toast";
import {
  GET_COMMENT,
  GET_LIKE,
  GET_VIDEO,
  DELETE_VIDEO,
  DELETE_COMMENT,
  INSERT_VIDEO,
  EDIT_VIDEO,
  HANDLE_LOCK_SWITCH,
} from "./types";

export const getVideo =
  (id, start, limit, sDate, eDate, type) => (dispatch) => {
    const url =
      id !== null
        ? `getVideo?userId=${id}`
        : type === "fake"
        ? `getVideo?start=${start}&limit=${limit}&startDate=${sDate}&endDate=${eDate}&type=${type}`
        : `getVideo?start=${start}&limit=${limit}&startDate=${sDate}&endDate=${eDate}`;
    axios
      .get(url)
      .then((res) => {
        if (res.data.status) {
          dispatch({
            type: GET_VIDEO,
            payload: { video: res.data.video, total: res.data.total },
          });
        } else {
          Toast("error", res.data.message);
        }
      })
      .catch((error) => Toast("error", error.message));
  };

export const getComment = (videoId) => (dispatch) => {
  axios
    .get(`comment?videoId=${videoId}&type=ADMIN`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_COMMENT, payload: res.data.data });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const getLike = (videoId) => (dispatch) => {
  axios
    .get(`likes?videoId=${videoId}&type=ADMIN`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: GET_LIKE, payload: res.data.data });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const deleteVideo = (videoId) => (dispatch) => {
  axios
    .delete(`deleteRelite/${videoId}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: DELETE_VIDEO, payload: videoId });
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const allowDisallowComment = (videoId) => (dispatch) => {
  axios
    .patch(`/relite/commentSwitch/${videoId}`)
    .then((res) => {
      if (res.data.status) {
        localStorage.setItem("VideoDetail", JSON.stringify(res.data.video));
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const deleteComment = (commentId) => (dispatch) => {
  const requestOptions = {
    method: "DELETE",
    headers: {
      key: key,
    },
  };

  fetch(`${baseURL}comment?commentId=${commentId}`, requestOptions)
    .then((response) => response.json())
    .then((res) => {
      if (res.status) {
        dispatch({ type: DELETE_COMMENT, payload: commentId });
      } else {
        Toast("error", res.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

//create fake video
export const insertVideo = (data) => (dispatch) => {
  axios
    .post(`uploadRelite`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: INSERT_VIDEO, payload: res.data.video });
        Toast("success", "Video Insert Successful");
        setTimeout(() => {
          window.location.href = "/admin/video/fake";
        }, 3000);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};
//update fake video
export const editVideo = (id, data) => (dispatch) => {
  axios
    .patch(`editRelite/${id}`, data)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: EDIT_VIDEO,
          payload: { data: res.data.video, id: id },
        });
        Toast("success", "Video Update Successful");
        setTimeout(() => {
          window.location.href = "/admin/video/fake";
        }, 3000);
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => Toast("error", error.message));
};

export const handleLockSwitch = (data) => (dispatch) => {
  axios
    .patch(`videoIsLock?videoId=${data._id}`)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: HANDLE_LOCK_SWITCH,
          payload: { data: res.data.video, id: data._id },
        });
        Toast(
          "success",
          ` ${data.isLock ? "Unlocked" : "Locked"} SuccessFully ! `
        );
      } else {
        Toast("error", res.data.message);
      }
    })
    .catch((error) => console.log(error));
};
